import { Promise } from 'es6-promise';
import { FetchProxy } from 'intraactive-sdk-helper';
import { sdkContainerInstance } from '../global/Container';
import { Environment } from './Environment';

export default class TranslationService {

  public getTranslations(environment: "Development" | "Test" | "Production", token: string, tenant: string, title: string, categories: string, text: string, to: string) {
    return new Promise((resolve, reject) => {
      if (environment && tenant && token && title && text && to) {
        const body: string = JSON.stringify(
          {
            Texts: [title, categories, text ? text : ''],
            TranslateTo: to
          }
        );

        return new FetchProxy(`${Environment.getEnvironmentForTranslationAPI(environment)}/${tenant}/translate`,
          {
            method: 'POST',
            headers: {
              'AuthToken': token,
              'Content-Type': 'application/json'
            },
            body: body
          })
          .withContainer(sdkContainerInstance)
          .fetch()
          .then((response) => {
            if (!response.ok) {
              reject();
            } else {
              return response.json();
            }
          }).then(response => {
            const translation = [];
            response.forEach(translateText => {
              translation.push(translateText);
            });
            resolve(translation);
          }).catch((error) => {
            console.error(error);
            reject();
          });
      } else {
        resolve(null);
      }
    });
  }

}
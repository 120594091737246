import { IEnvironment } from "../interfaces/IEnvironment";

export class Environment {

  public static getEnvironmentForTranslationAPI(environment: IEnvironment): string {
    switch (environment) {
      case "Production":
        return "https://translate.api.intraactive.net";
      case "Test":
        return "https://translate.api-test.intraactive.net";
      case "Development":
        return "https://intraactive-translate-functions-dev.azurewebsites.net";
    }
  }

  public static getEnvironmentForTranslationFunctionKey(environment: IEnvironment): string {
    switch (environment) {
      case "Production":
        return "FvMm7ay4I66yzJuZ1UuX/PG7i1avC5HzCv5tLTgbYNpGTnUPip/4og==";
      case "Test":
        return "";
      case "Development":
        return "";
    }
  }

}

import * as React from 'react';
import './LocalizerApp.css';
import { IAMsalLogin } from 'intraactive-sdk-ui/dist/MsalLogin';
import { Provider } from 'mobx-react';
import { configure } from 'mobx';
import { Store } from './stores';
import { Localizer } from './components/Localizer';

export interface ILocalizerProps {
}

export interface IState {
}

export default class LocalizerApp extends React.Component<ILocalizerProps> {

  private store: Store;

  constructor(props: ILocalizerProps) {
    super(props);
    this.store = new Store();
    configure({
      isolateGlobalState: true,
      enforceActions: "never"
    });
    this.updateStore(props);
  }

  public componentWillReceiveProps(props: ILocalizerProps) {
    this.updateStore(props);
  }

  private updateStore(props: ILocalizerProps): void {
    console.log(props);
  }

  render() {
    return (
      <div className='IA_localizerApp'>
        <IAMsalLogin
          clientId="a52605ed-bd27-4e8a-9dae-528df434cdf3"
          msalScope="https://intraactiveworkplace.onmicrosoft.com/25d85c69-d640-427c-af92-8671deeb5f1b/user_impersonation"
          redirectMethod="redirect"
          redirectUri="https://localizer.intraactive.net"
          // redirectUri="http://localhost:3000" // For local debugging - do not check in to git
        >
          <Provider store={this.store}>
            <Localizer />
          </Provider>
        </IAMsalLogin>
      </div>
    );
  }
}
import { FetchProxy } from "intraactive-sdk-helper";
import { sdkContainerInstance } from '../global/Container';
import { IEnvironment } from "../interfaces/IEnvironment";
import { ILocalizationKey } from "../interfaces/ILocalizationKey";
import { LOCALIZATION } from '../localization/localization';
import { Environment } from "./Environment";

declare global {
  interface Window { language: string; localizedStrings: any, localizedStringsIsRequested: boolean }
}

export class LocalizationService {

  public strings = LOCALIZATION;

  public constructor(forceLanguage?: string) {
    this.setLocalization(forceLanguage);
  }

  private setLocalization(forceLanguage?: string): void {
    if (window.localizedStrings) {
      this.setLocalizedStrings();
    } else {
      if (localStorage.getItem("IA_localizedStrings")) {
        window.localizedStrings = JSON.parse(localStorage.getItem("IA_localizedStrings"));
      } else {
        setTimeout(() => {
          this.setLocalization(forceLanguage);
        }, 500);
      }
      if (!window.localizedStringsIsRequested) {
        window.localizedStringsIsRequested = true;
        this.getLocalizedKeysForLanguage(forceLanguage ? forceLanguage : this.getLanguageCode()).then((localizedStrings) => {
          window.localizedStrings = localizedStrings;
          localStorage.setItem("IA_localizedStrings", JSON.stringify(localizedStrings));
          this.setLocalizedStrings();
        })
      }
    }
  }

  private setLocalizedStrings(): void {
    for (const key in this.strings) {
      if (this.strings.hasOwnProperty(key)) {
        if (window.localizedStrings[key]) {
          this.strings[key] = window.localizedStrings[key];
        }
      }
    }
  }

  public getLanguageFromNavigator(forceLanguage?: string): 'Danish' | 'English' | 'Swedish' | 'German' | 'Norwegian' {
    switch (forceLanguage ?? navigator.language.substring(0, 2)) {
      case 'en':
        return 'English';
      case 'da':
        return 'Danish';
      case 'sv':
        return 'Swedish';
      case 'de':
        return 'German';
      case 'nb':
        return 'Norwegian';
      default:
        return 'English';
    }
  }

  public getLanguageCode() {
    let language = this.getLanguageFromWindowOrCache();
    let defaultLanguage = this.getLanguageFromNavigator();
    switch (language ?? defaultLanguage) {
      case 'English':
        return 'en-US';
      case 'Danish':
        return 'da-DK';
      case 'Swedish':
        return 'sv-SE';
      case 'Norwegian':
        return 'nb-NO';
      case 'German':
        return 'de-DE';
      default:
        return 'en-US';
    }
  }

  public getFromString(key: string): string {
    if (window.localizedStrings && window.localizedStrings[key]) {
      return window.localizedStrings[key];
    } else {
      return key;
    }
  }

  private getLanguageFromWindowOrCache() {
    if (window.language) {
      return window.language;
    } else {
      if (window.localStorage && !!window.localStorage.getItem("IA_language")) {
        return window.localStorage.getItem("IA_language");
      }
    }
  }

  private getLocalizedKeysForLanguage(language: string): Promise<any> {
    return new Promise<any>((resolve) => {
      new FetchProxy(`${Environment.getEnvironmentForTranslationAPI("Production")}/translations/${language}`, {
        method: "GET"
      })
        .withContainer(sdkContainerInstance)
        .fetch()
        .then((response) => {
          if (!response.ok) {
            return;
          }
          return response.json();
        })
        .then((keys) => {
          resolve(keys);
        })
        .catch(error => {
          console.log(error);
          resolve([]);
        });
    });
  }

  // LOCALIZER ENDPOINTS

  public static getComponents(): Promise<string[]> {
    return new Promise<string[]>((resolve) => {
      new FetchProxy(`${Environment.getEnvironmentForTranslationAPI("Production")}/translationmodules`, {
        method: "GET"
      })
        .withContainer(sdkContainerInstance)
        .withHeader("x-functions-key", Environment.getEnvironmentForTranslationFunctionKey("Production"))
        .fetch()
        .then((response) => {
          if (!response.ok) {
            return;
          }
          return response.json();
        })
        .then((modules) => {
          resolve(modules);
        })
        .catch(error => {
          console.log(error);
          resolve([]);
        });
    });
  }

  public static getLocalizedKeysForKey(key: string): Promise<ILocalizationKey[]> {
    return new Promise<ILocalizationKey[]>((resolve) => {
      new FetchProxy(`${Environment.getEnvironmentForTranslationAPI("Production")}/translations?module=${key}`, {
        method: "GET"
      })
        .withContainer(sdkContainerInstance)
        .withHeader("x-functions-key", Environment.getEnvironmentForTranslationFunctionKey("Production"))
        .fetch()
        .then((response) => {
          if (!response.ok) {
            return;
          }
          return response.json();
        })
        .then((keys) => {
          resolve(keys);
        })
        .catch(error => {
          console.log(error);
          resolve([]);
        });
    });
  }

  public static getLatestChangedLocalizedKeys(): Promise<ILocalizationKey[]> {
    return new Promise<ILocalizationKey[]>((resolve) => {
      new FetchProxy(`${Environment.getEnvironmentForTranslationAPI("Production")}/latesttranslations`, {
        method: "GET"
      })
        .withContainer(sdkContainerInstance)
        .withHeader("x-functions-key", Environment.getEnvironmentForTranslationFunctionKey("Production"))
        .fetch()
        .then((response) => {
          if (!response.ok) {
            return;
          }
          return response.json();
        })
        .then((keys) => {
          resolve(keys);
        })
        .catch(error => {
          console.log(error);
          resolve([]);
        });
    });
  }

  public static getLocalizedKeys(): Promise<ILocalizationKey[]> {
    return new Promise<ILocalizationKey[]>((resolve) => {
      new FetchProxy(`${Environment.getEnvironmentForTranslationAPI("Production")}/translations`, {
        method: "GET"
      })
        .withContainer(sdkContainerInstance)
        .withHeader("x-functions-key", Environment.getEnvironmentForTranslationFunctionKey("Production"))
        .fetch()
        .then((response) => {
          if (!response.ok) {
            return;
          }
          return response.json();
        })
        .then((keys) => {
          resolve(keys);
        })
        .catch(error => {
          console.log(error);
          resolve([]);
        });
    });
  }

  public static updatelocalizedKey(key: ILocalizationKey): Promise<number> {
    return new Promise<number>((resolve) => {
      new FetchProxy(`${Environment.getEnvironmentForTranslationAPI("Production")}/translations`, {
        method: "PUT",
        body: JSON.stringify(key)
      })
        .withContainer(sdkContainerInstance)
        .withHeader("x-functions-key", Environment.getEnvironmentForTranslationFunctionKey("Production"))
        .fetch()
        .then((response) => {
          if (!response.ok) {
            return;
          }
          setTimeout(() => {
            resolve(200);
          }, 2000);
        })
        .catch(error => {
          console.log(error);
          resolve(error.status);
        });
    });
  }

  public static getRandomGif(): Promise<string> {
    return new Promise<string>((resolve) => {
      const giphy = {
        baseURL: "https://api.giphy.com/v1/gifs/",
        apiKey: "tcbQ8Zub5twnOyfnzUBvSbz97zuJN9nv",
        tag: "celebrations dance",
        type: "random",
        rating: "pg-13"
      };
      // Giphy API URL
      let giphyURL = encodeURI(
        giphy.baseURL +
        giphy.type +
        "?api_key=" +
        giphy.apiKey +
        "&tag=" +
        giphy.tag +
        "&rating=" +
        giphy.rating
      );
      new FetchProxy(giphyURL, {
        method: "GET",
      })
        .withContainer(sdkContainerInstance)
        .fetch()
        .then((response) => {
          if (!response.ok) {
            return;
          }
          return response.json();
        })
        .then((gif) => {
          resolve(gif.data.images.original.url);
        })
        .catch(error => {
          console.log(error);
          resolve("");
        });
    });
  }

  public static getEnvironmentForTranslationAPI(environment: IEnvironment): string {
    switch (environment) {
      case "Production":
        return "https://translate.api.intraactive.net";
      case "Test":
        return "https://translate.api-test.intraactive.net";
      case "Development":
        return "https://intraactive-translate-functions-dev.azurewebsites.net";
    }
  }
}
import * as React from 'react';
import { inject, observer } from 'mobx-react';
import { Store } from '../../stores';
import { IAButton } from 'intraactive-sdk-ui/dist/Button';
import { IAPanel } from 'intraactive-sdk-ui/dist/panels';
import { IATextField } from  'intraactive-sdk-ui/dist/TextField';
import { LocalizationService } from '../../services/LocalizationService';
import { ILocalizationKey } from '../../interfaces/ILocalizationKey';
import TranslationService from '../../services/TranslationService';
import '../Localizer.css';

export interface IAddKeyPanelProps {
  open: boolean;
  store?: Store;
  onClose: () => void;
  reload: () => void;
}

export interface IAddKeyPanelState {
  key?: string;
  languagesTextFields?: JSX.Element[];
  disableSave?: boolean;
  savingKey?: boolean;
  isTranslating: boolean;
}

@inject('store')
@observer
export class AddKeyPanel extends React.Component<IAddKeyPanelProps, IAddKeyPanelState> {

  constructor(props: IAddKeyPanelProps) {
    super(props);
    this.state = {
      isTranslating: false
    };
  }

  public componentWillReceiveProps(props: IAddKeyPanelProps): void {
    if (props.open) {
      if (this.props.store.selectedTag) {
        const keyField = document.getElementById("NewKey");
        this.setState({ key: `${this.props.store.selectedTag}_` }, () => keyField.focus());
      }
      const languagesTextFields = [];
      this.props.store.languages?.forEach((language: string) => {
        languagesTextFields.push(
          <div key={`languagesTextFields_${language}`}>
            <IATextField
              id={`languagesTextFields_${language}`}
              required={language === "en-us"}
              label={language}
              text={""}
              onChange={() => this.validateSave()}
            />
            <input
              id={`languagesCheckbox_${language}`}
              type="Checkbox"
              style={{
                marginTop: 5
              }}
            />
            Need review
          </div>
        )
      });
      this.setState({ languagesTextFields });
      this.validateSave();
    }
  }

  private saveLocalizations(): void {
    let numberOfSavedLanguages = 0;
    this.props.store.languages?.forEach((language: string) => {
      const languageField: any = document.getElementById(`languagesTextFields_${language}`);
      const needReview: any = document.getElementById(`languagesCheckbox_${language}`);
      if (language !== "en-us" && languageField.value !== "") {
        const localizationKey: ILocalizationKey = {
          key: this.state.key,
          langCode: language,
          value: languageField.value,
          autoTranslated: needReview?.checked
        }
        LocalizationService.updatelocalizedKey(localizationKey).then(() => {
          numberOfSavedLanguages++;
          if (numberOfSavedLanguages === this.props.store.languages.length) {
            this.setState({ savingKey: false, key: undefined }, () => {
              this.close();
            });
          }
        })
      } else {
        numberOfSavedLanguages++;
        if (numberOfSavedLanguages === this.props.store.languages.length) {
          this.setState({ savingKey: false, key: undefined }, () => {
            this.close();
          });
        }
      }
    });
  }

  private validateSave(): void {
    const defaultTextField: any = document.getElementById(`languagesTextFields_en-us`);
    this.setState({ disableSave: defaultTextField?.value === "" || this.state.key == undefined || this.props.store.keys.indexOf(this.state.key) !== -1 });
  }

  private autoTranslate(defaultLabel: string): void {
    this.setState({ isTranslating: true });
    this.recursivelyTranslate(JSON.parse(JSON.stringify(this.props.store.languages)), defaultLabel);
  }

  private recursivelyTranslate(languages: string[], defaultLabel: string) {
    const language = languages.shift();
    if (!language) {
      this.setState({ isTranslating: false });
      return;
    };

    const translationService = new TranslationService();
    const textField: any = document.getElementById(`languagesTextFields_${language}`);
    if (textField.value === "") {
      translationService.getTranslations("Development", "fb06d1ae-d04d-4a22-9740-1f96e8bc449f", "intraactivedev", defaultLabel, "-", "-", language?.substring(0, 2)).then((translation) => {
        textField.value = translation[0];
        const needReview: any = document.getElementById(`languagesCheckbox_${language}`);
        needReview.checked = true;
        this.recursivelyTranslate(languages, defaultLabel);
      }).catch(() => {
        this.recursivelyTranslate(languages, defaultLabel);
      });
    } else {
      this.recursivelyTranslate(languages, defaultLabel);
    }
  }

  private close(): void {
    document.body.style.overflowY = "auto";
    document.body.style.height = "inherit";
    this.props.store.languages.forEach((language: string) => {
      let checkbox: any = document.getElementById(`languagesCheckbox_${language}`);
      checkbox.checked = false;
    })
    this.setState({ key: undefined });
    this.props.onClose()
  }

  public render(): JSX.Element {
    // TAGS
    const tags = [];
    this.props.store.tags?.forEach((tag: string) => {
      tags.push(
        <div
          key={`tag_${tag}`}
          className="IA_tag"
          style={{
            backgroundColor: tag === this.props.store.selectedTag ? "#333333" : "#eeeeee",
            color: tag === this.props.store.selectedTag ? "#ffffff" : "#333333",
          }}
          onClick={() => {
            this.props.store.selectedTag = tag;
            this.props.reload();
            const keyField = document.getElementById("NewKey");
            this.setState({ key: `${tag}_` }, () => keyField.focus());
          }}
        >
          {tag}
        </div>
      )
    })
    return (
      <IAPanel
        showNavigationBar={true}
        component={"AppAdmin"}
        open={this.props.open}
        transition="slide"
        width={600}
        isMobile={false}
        highlightColor={"#23879b"}
        close={() => this.close()}
      >
        <div style={{
          padding: 40
        }}>
          <h2>New key and localizations</h2>
          <div
            className="IA_tags"
            style={{
              marginTop: 10
            }}
          >
            {tags}
          </div>
          <IATextField
            id="NewKey"
            required
            label={"New key"}
            text={this.state.key}
            inputFieldStyle={{
              borderColor: (this.props.store.keys?.indexOf(this.state.key) === -1 && this.state.key?.includes("_")) || this.state.key === "" || this.state.key == undefined ? "#dddddd" : "red",
              color: (this.props.store.keys?.indexOf(this.state.key) === -1 && this.state.key?.includes("_")) || this.state.key === "" || this.state.key === "" ? "#333333" : "red"
            }}
            onChange={(key: string) => {
              this.setState({ key }, () => this.validateSave());
            }}
          />
          {this.state.languagesTextFields}
          <IAButton
            label="Create"
            buttonColor="#2daa00"
            textColor='#ffffff'
            borderRadius={5}
            disbaled={this.state.disableSave || this.state.savingKey || this.state.isTranslating}
            showSpinner={this.state.savingKey}
            style={{
              marginTop: 20,
              marginBottom: 50
            }}
            onClick={() => {
              this.setState({ savingKey: true });
              const defaultTextField: any = document.getElementById(`languagesTextFields_en-us`);
              const needReview: any = document.getElementById(`languagesCheckbox_en-us`);
              const localizationKey: ILocalizationKey = {
                key: this.state.key,
                langCode: "en-us",
                value: defaultTextField.value,
                autoTranslated: needReview.checked
              }
              LocalizationService.updatelocalizedKey(localizationKey).then((statusCode: number) => {
                if (statusCode === 200) {
                  this.saveLocalizations();
                } else {
                  this.setState({ savingKey: false });
                  alert("Failed with status code: " + statusCode);
                }
              });
            }}
          />
          <IAButton
            label="Auto translate"
            buttonColor="gray"
            textColor='#ffffff'
            borderRadius={5}
            showSpinner={this.state.isTranslating}
            disbaled={this.state.disableSave || this.state.savingKey || this.state.isTranslating}
            style={{
              marginTop: 20,
              marginLeft: 10,
              marginBottom: 50
            }}
            onClick={() => {
              const defaultTextField: any = document.getElementById(`languagesTextFields_en-us`);
              if (defaultTextField?.value !== "") {
                this.autoTranslate(defaultTextField.value);
              }
            }}
          />
        </div>
      </IAPanel>
    );
  }
}
import * as React from 'react';
import './Localizer.css';
import { inject, observer } from 'mobx-react';
import { Store } from '../stores';
import { LocalizationService } from '../services/LocalizationService';
import { ILocalizationKey } from '../interfaces/ILocalizationKey';
import { IAButton } from "intraactive-sdk-ui/dist/Button";
import { IASpinner } from "intraactive-sdk-ui/dist/Spinner";
import { AddKeyPanel } from './panels/AddKeyPanel';
import TranslationService from '../services/TranslationService';

export interface ILocalizerProps {
  store?: Store;
}

export interface ILocalizerState {
  showAddNewKeyPanel?: boolean;
  savingKey?: boolean;
  copied?: boolean;
  gif?: string;
  loading?: boolean;
  generatingTSInterface?: boolean;
}

@inject('store')
@observer
export class Localizer extends React.Component<ILocalizerProps, ILocalizerState> {

  constructor(props: ILocalizerProps) {
    super(props);
    this.state = {
      loading: true
    };
    this.getComponents();
  }

  private getComponents(): void {
    LocalizationService.getComponents().then((components: string[]) => {
      this.props.store.tags = components;
      if (!this.props.store.selectedTag) {
        this.props.store.selectedTag = this.props.store.tags[0];
      }
      this.loadLocalization();
    })
  }

  private loadLocalization(): void {
    this.setState({ loading: true });
    if (this.props.store.selectedTag) {
      LocalizationService.getLocalizedKeysForKey(this.props.store.selectedTag).then((localizations: ILocalizationKey[]) => {
        this.props.store.localizations = localizations;
        this.props.store.keys = Object.keys(this.props.store.localizations.reduce((r, { key }) => (r[key] = '', r), {}));
        this.getLocalizedKeys();
        this.forceUpdate();
      })
    } else {
      LocalizationService.getLatestChangedLocalizedKeys().then((localizations: ILocalizationKey[]) => {
        this.props.store.localizations = localizations;
        this.props.store.keys = Object.keys(this.props.store.localizations.reduce((r, { key }) => (r[key] = '', r), {}));
        this.getLocalizedKeys();
        this.forceUpdate();
      })
    }
  }

  private getLocalizedKeys(): void {
    this.props.store.localizedKeys = [];
    this.props.store.keys.forEach((key: string) => {
      // Create table content
      const localizationsForKey: ILocalizationKey[] = new Array(this.props.store.languages?.length);
      this.props.store.localizations.forEach((localization: ILocalizationKey) => {
        if (localization.key === key) {
          localizationsForKey[this.props.store.languages.indexOf(localization.langCode)] = localization;
        }
      });
      this.props.store.localizedKeys.push({
        key: key,
        localizations: localizationsForKey
      })
    });
    setTimeout(() => {
      this.setState({ loading: false });
      this.forceUpdate();
    }, 0);
  }

  private generateTypescriptIntercase(): void {
    this.setState({generatingTSInterface: true});
    LocalizationService.getLocalizedKeys().then((allLocalizationKeys: any[]) => {
      const allKeys = Object.keys(allLocalizationKeys.reduce((r, { key }) => (r[key] = '', r), {}));
      let keys = "export const LOCALIZATION = {\n"
      allKeys.forEach((key: string) => {
        keys += `${key}: undefined as string,\n`;
      });
      keys += "}"
      navigator.clipboard.writeText(keys);
      LocalizationService.getRandomGif().then((gif: string) => {
        this.setState({ copied: true, gif: gif, generatingTSInterface: false }, () => {
          setTimeout(() => {
            this.setState({ copied: false });
            setTimeout(() => {
              this.setState({ gif: undefined });
            }, 500);
          }, 3000);
        })
      })
    })
  }

  public render(): JSX.Element {
    // ROWS
    const rows = [];
    const incompleteLanguages = [];
    let oneHourAgo = new Date();
    oneHourAgo.setHours(oneHourAgo.getHours() - 1);
    let oneDayAgo = new Date();
    oneDayAgo.setHours(oneDayAgo.getHours() - 24);
    this.props.store.localizedKeys?.forEach((localizedKey: any, localizedKeyIndex) => {
      let defaultTranslation = "";
      if (this.props.store.selectedTag == undefined || this.props.store.selectedTag === localizedKey.key.split("_")[0]) {
        const rowElements = [];
        rowElements.push(
          <td
            key={`localizedKeyIndex_${localizedKeyIndex}`}
            style={{
              backgroundColor: localizedKey?.localizations && localizedKey?.localizations[0] && new Date(localizedKey?.localizations[0].lastUpdated) > oneDayAgo ? (localizedKey?.localizations && localizedKey?.localizations[0] && new Date(localizedKey?.localizations[0].lastUpdated) > oneHourAgo ? "#b5e8ed" : "#dff4f7") : "transparent",
            }}
          >
            {localizedKey.key}
          </td>
        );
        localizedKey.localizations?.forEach((localization: ILocalizationKey, localizationIndex) => {
          if ((localization?.value == undefined || localization?.value === "") && incompleteLanguages.indexOf(this.props.store.languages[localizationIndex]) == -1) {
            incompleteLanguages.push(this.props.store.languages[localizationIndex]);
          }
          if (localization?.langCode === "en-us") {
            defaultTranslation = localization?.value;
          }
          rowElements.push(
            <td
              key={`${localizedKey.key}_${localizationIndex}`}
              suppressContentEditableWarning
              contentEditable
              style={{
                backgroundColor: document.getElementById(`${localizedKey.key}_${localizationIndex}`)?.innerText === "Write your translation here..." && this.props.store.localizedKeyToEdit !== `${localizedKey.key}_${localizationIndex}` ? "#ffecec" : (localization?.autoTranslated ? "#faf7c8" : (new Date(localization?.lastUpdated) > oneDayAgo ? (new Date(localization?.lastUpdated) > oneHourAgo ? "#b5e8ed" : "#dff4f7") : "transparent")),
                color: document.getElementById(`${localizedKey.key}_${localizationIndex}`)?.innerText === "Write your translation here..." && this.props.store.localizedKeyToEdit !== `${localizedKey.key}_${localizationIndex}` ? "#ffecec" : "#333333"
              }}
              onFocus={() => {
                if (this.props.store.localizedKeyToEdit !== `${localizedKey.key}_${localizationIndex}`) {
                  if (this.props.store.originalText) {
                    // Reset changed text in previous field to original text
                    const localizedKeyToEdit = document.getElementById(this.props.store.localizedKeyToEdit);
                    localizedKeyToEdit.innerText = this.props.store.originalText;
                  }
                  // Set new original text
                  this.props.store.localizedKeyToEdit = `${localizedKey.key}_${localizationIndex}`;
                  this.props.store.originalText = localization?.value;
                  const localizedKeyToEdit = document.getElementById(this.props.store.localizedKeyToEdit);
                  if (localizedKeyToEdit?.innerText === "Write your translation here...") {
                    window.getSelection().selectAllChildren(
                      localizedKeyToEdit
                    );
                  }
                }
              }}
            >
              <div id={`${localizedKey.key}_${localizationIndex}`}>
                {localization?.value == undefined || localization?.value === "" ? "Write your translation here..." : localization?.value}
              </div>
              {this.props.store.localizedKeyToEdit === `${localizedKey.key}_${localizationIndex}` &&
                <div contentEditable={false}>
                  <IAButton
                    label="Save"
                    buttonColor="#025159"
                    textColor='#ffffff'
                    disbaled={this.state.savingKey}
                    showSpinner={this.state.savingKey}
                    borderRadius={5}
                    style={{
                      marginTop: 10,
                      marginRight: 10
                    }}
                    onClick={() => {
                      const localizedKeyToEdit = document.getElementById(this.props.store.localizedKeyToEdit);
                      if (!localization) {
                        localization = {
                          key: localizedKey.key,
                          langCode: this.props.store.languages[localizationIndex]
                        }
                      }
                      localization.value = localizedKeyToEdit?.innerText;
                      localization.autoTranslated = false;
                      this.setState({ savingKey: true });
                      LocalizationService.updatelocalizedKey(localization).then((statusCode: number) => {
                        if (statusCode === 200) {
                          this.props.store.localizedKeyToEdit = undefined;
                          this.setState({ savingKey: false });
                          this.props.store.originalText = undefined;
                          this.loadLocalization();
                        } else {
                          this.setState({ savingKey: false });
                          alert("Failed with status code: " + statusCode);
                        }
                      });
                    }}
                  />
                  <IAButton
                    label="Cancel"
                    buttonColor="gray"
                    textColor='#ffffff'
                    disbaled={this.state.savingKey}
                    borderRadius={5}
                    style={{
                      marginTop: 10
                    }}
                    onClick={() => {
                      if (this.props.store.originalText) {
                        const localizedKeyToEdit = document.getElementById(this.props.store.localizedKeyToEdit);
                        localizedKeyToEdit.innerText = this.props.store.originalText;
                        this.props.store.localizedKeyToEdit = undefined;
                        this.props.store.originalText = undefined;
                      } else {
                        const localizedKeyToEdit = document.getElementById(this.props.store.localizedKeyToEdit);
                        localizedKeyToEdit.innerText = "Write your translation here...";
                        this.props.store.localizedKeyToEdit = undefined;
                      }
                    }}
                  />
                  <IAButton
                    label="Translate & save"
                    buttonColor="#025159"
                    textColor='#ffffff'
                    disbaled={this.state.savingKey}
                    showSpinner={this.state.savingKey}
                    borderRadius={5}
                    style={{
                      marginTop: 10,
                      marginRight: 10
                    }}
                    onClick={() => {
                      const localizedKeyToEdit = document.getElementById(this.props.store.localizedKeyToEdit);
                      if (!localization) {
                        localization = {
                          key: localizedKey.key,
                          langCode: this.props.store.languages[localizationIndex]
                        }
                      }
                      this.setState({ savingKey: true });
                      new TranslationService().getTranslations("Development", "fb06d1ae-d04d-4a22-9740-1f96e8bc449f", "intraactivedev", defaultTranslation, "-", "-", this.props.store.languages[localizationIndex]?.substring(0, 2)).then((translation: string) => {
                        localization.value =  translation[0];
                        localization.autoTranslated = true;
                        LocalizationService.updatelocalizedKey(localization).then((statusCode: number) => {
                          if (statusCode === 200) {
                            this.props.store.localizedKeyToEdit = undefined;
                            this.setState({ savingKey: false });
                            this.props.store.originalText = undefined;
                            this.loadLocalization();
                          } else {
                            this.setState({ savingKey: false });
                            alert("Failed with status code: " + statusCode);
                          }
                        });
                      });
                    }}
                  />
                </div>
              }
            </td>
          );
        })
        rows.push(
          <tr key={`localizationKeyRow_${localizedKeyIndex}`}>
            {rowElements}
          </tr>
        );
      }
    });
    // HEADERS
    const headers = [];
    headers.push(<th key={`header_key`}>KEY</th>);
    this.props.store.languages?.forEach((language: string) => {
      headers.push(
        <th
          key={`language_${language}`}
          style={{
            color: incompleteLanguages.indexOf(language) == -1 ? "#333333" : "red"
          }}
        >
          {language?.toUpperCase()}
        </th>
      );
    });
    // TAGS
    const tags = [];
    this.props.store.tags?.forEach((tag: string) => {
      tags.push(
        <div
          key={`tag_${tag}`}
          className={"IA_tag"}
          style={{
            backgroundColor: tag === this.props.store.selectedTag ? "#333333" : "#eeeeee",
            color: tag === this.props.store.selectedTag ? "#ffffff" : "#333333",
          }}
          onClick={() => {
            this.props.store.localizedKeyToEdit = undefined;
            this.props.store.originalText = undefined;
            this.props.store.selectedTag = tag;
            this.loadLocalization();
          }}
        >
          {tag}
        </div>
      )
    })
    return (
      <div className="IA_localizerWrapper">
        <AddKeyPanel
          open={this.state.showAddNewKeyPanel}
          onClose={() => this.setState({ showAddNewKeyPanel: false }, () => {
            this.loadLocalization();
          })}
          reload={() => this.getLocalizedKeys()}
        />
        <div
          id="IA_localizer"
          className="IA_localizer"
        >
          {this.props.store.tags ?
            <div>
              <IAButton
                label="Add key"
                buttonColor="#025159"
                textColor='#ffffff'
                borderRadius={5}
                style={{
                  marginBottom: 20
                }}
                onClick={() => {
                  this.setState({ showAddNewKeyPanel: true });
                }}
              />
              <IAButton
                label="Export Typescript interface"
                buttonColor="#025159"
                textColor='#ffffff'
                borderRadius={5}
                showSpinner={this.state.generatingTSInterface}
                style={{
                  marginBottom: 20,
                  marginLeft: 10
                }}
                onClick={() => this.generateTypescriptIntercase()}
              />
              <div className="IA_tags">
                {tags}
              </div>
              {this.props.store.localizedKeys && !this.state.loading ?
                <table style={{ width: "100%" }}>
                  <tbody>
                    <tr>
                      {headers}
                    </tr>
                    {rows}
                  </tbody>
                </table>
                :
                <div style={{
                  clear: "both"
                }}>
                  <IASpinner />
                </div>
              }
            </div>
            :
            <IASpinner />
          }
          <div
            className="IA_copiedPopup"
            style={{
              transform: this.state.copied ? "scale(1)" : "scale(0)",
            }}
          >
            <img
              width={500}
              height={500}
              src={this.state.gif}
              style={{
                objectFit: "contain",
                marginLeft: "calc(50% - 250px)",
                marginTop: "calc(50vh - 300px)",
              }}
            />
            <h1>Copied to clipboard!</h1>
          </div>
        </div>
      </div>
    );
  }
}
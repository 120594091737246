import { observable } from "mobx";
import { ILocalizationKey } from "../interfaces/ILocalizationKey";

export class Store {

  @observable public localizations: ILocalizationKey[];
  @observable public languages: string[] = ["en-us", "da-dk", "de-de", "nb-no", "sv-se"];
  @observable public keys: string[];
  @observable public localizedKeys: {
    key: string,
    localizations: ILocalizationKey[]
  }[];
  @observable public originalText: string;
  @observable public localizedKeyToEdit: string;
  @observable public tags: string[];
  @observable public selectedTag: string;
}